import React from "react"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from 'gatsby'
import Landing from '../route/Landing/index'

export default function Home({ location, blok }) {
  return (
    <StaticQuery
      query={graphql`{
      posts: allStoryblokEntry(filter: {full_slug: {regex: "/el/blog/(.)+/"}}, limit: 3, sort: {order: DESC, fields: first_published_at}) {
        edges {
          node {
            full_slug
            name
            field_image_string
            field_intro_string
            published_at
            first_published_at
          }
        }
      }}`
      }
      render={data => {
        return(
        <Layout location={location} t="el">
          <Landing posts={data} t="el" />
        </Layout>
      )}}
    />
  )
}